
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { MyImage } from '../../myImage'
import { CardDetail } from '../cardDetail'
import styles from '../css/recommend.module.scss'
import moment from 'moment'
import { CardActions } from '../CardActions'
import { cardDataToUrl } from '../../../utils/cardData2Url'
import Link from 'next/link'
import { 
    faClock,
} from '@fortawesome/free-regular-svg-icons';
import Paper from '@mui/material/Paper'
import dynamic from 'next/dynamic'

const DynamicMyImage = dynamic(() => import('../../myImage')
  .then(module => module.MyImage), { 
        ssr: false, 
        loading: () => <div className={styles.cardImage}>Loading...</div> 
    })

export const NormalCard = ({
    onClick,
    onMouseDown,
    d,
    action,
    locale,
    t,
    name,
    imageUrl,
    imageurlfallback,
    location,
    popOverState,
    setPopOverState,
    inquiryState,
    setInquiryState,
    appointmentState,
    setAppointmentState,
    getPropertyPrice,
    language
}) => {
    return (
        <div key={d.property.id} className={styles.cardItem}>
        <Paper key={d.property.id} >
        <div className={styles.cardContainerOutter}>
            <div className={styles.cardHeader}>
                <div className={styles.cardImagecontainer}
                    // onClick={() => onClick(d)}
                    // onMouseDown={(e) => onMouseDown(e, d)}
                >
                    {
                        (d?.property?.boots_status === 1) ?
                            <div className={styles.exclusiveLabel}>ประกาศ Exclusive</div>
                            : undefined
                    }
                    <a href={`${cardDataToUrl(d, action, locale, t)}`} target='_blank'>
                        <DynamicMyImage className={styles.cardImage} alt={name || null} src={`${imageUrl}`} width="100%" height="100%"
                            imageurlfallback={`${imageurlfallback}`}
                            onError={function () {
                                console.log('image error xxxxxxxx')
                                // this.style.display='none';
                            }}
                        />
                    </a>
                </div>
            </div>
            <div className={styles.cardbody}>
                {/* <div className={styles.cardTitle}
                    onClick={() => onClick(d)}
                    onMouseDown={(e) => onMouseDown(e, d)}
                > */}
                    <div className={styles.cardmeta}>
                        <Link legacyBehavior className="nav-link" href={`${cardDataToUrl(d, action, locale, t)}`} itemProp="url" passHref>
                            <a target="_blank">{name}</a>
                        </Link>
                    </div>
                {/* </div> */}
                <div>
                    <p className={styles.cardlocationcontainer} >{location}</p>
                    {/* <p>
                                        { d.property && d.property.sales === 1 ? <Tag color="#f50">{t('ขาย')}</Tag>: undefined }
                                        { d.property && d.property.rent === 1 ? <Tag color="#87d068">{t('เช่า')}</Tag>: undefined }
                                    </p>  */}
                    <div className={styles.cardDetailContainer}>
                        <div className={styles.priceContainer} >
                            {
                                getPropertyPrice(d, t, locale)
                            }
                        </div>
                        {/* <p>{d.property_ID}</p> */}
                        {/* <div className={styles.category}>{d.project?.category?.name[locale.toUpperCase()] || ''}</div> */}
                        <div className={styles.detail}>
                            {/* {detail} */}
                            <CardDetail property={d.property} project={d.project}
                                carddetail={d.carddetail} action={action} t={t} locale={locale} />
                        </div>
                    </div>
                    <div className={styles.postUserContainer}>
                        <div className={styles.postuser}>
                            {/* <Avatar  size="small" icon={<UserOutlined />} style={{marginRight: '5px'}} /> */}
                            {`${d.user[`first_name_${language}`] || ''} ${d.user[`last_name_${language}`] || ''}`}
                        </div>
                        <div style={{ display: "flex", justifyContent: 'right', alignItems: 'center', flexFlow: '1 1 auto' }}>
                            <FontAwesomeIcon icon={faClock} size="xs" className={styles.detailIcon} />
                            <div className={styles.updatedate} >{`${moment(d.property?.update).fromNow()}`}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.cardActions}>
                {
                    CardActions({
                        id: d.property.id,
                        type: 'property',
                        locale,
                        popOverState,
                        setPopOverState,
                        inquiryState,
                        setInquiryState,
                        appointmentState,
                        setAppointmentState,
                        imageURL: imageUrl,
                        name: name,
                        details: d,
                    })
                }
            </div>
        </div>
        </Paper>
        </div>
    )
}